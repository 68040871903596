.nav-profile {
  width: 14.5rem;

  .nav-profile-header {
    padding: 0.7rem 1rem;
    border-bottom: 1px solid $border-color;
  }

  .nav-profile-body {
    padding: 0.625rem 0;

    .ant-menu-vertical>.ant-menu-item {
      height: 2.1875rem;
      line-height: 2.1875rem;
    }
  }
}

.switch-account-button {
  border: none;

  &:hover {
    background-color: $gray-superlight;
  }
}

.ant-dropdown-menu {
  padding: 0px !important;
  margin: 0px !important;
}

// li.ant-menu-account-switch-child {
//   padding: 0 !important;
//   margin: 0 !important;
//   border: none !important;
//   height: initial !important;
//   line-height: initial !important;
// }