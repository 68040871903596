.uptime-monitor,
.performance-monitor {

    .uptime-monitor-table-name,
    .performance-monitor-table-name {
        display: block;
        font-weight: bold;
        font-size: 14px;
    }

    .uptime-monitor-table-url,
    .performance-monitor-table-url {
        font-size: 13px;
    }
}