.choose-account,
.nav-profile-body,
.create-account {
  .ant-card-body {
    padding: $card-spacer-y 0;
  }
}

.choose-account,
.create-account {

  .choose-account-list,
  .create-account-list {
    .ant-btn {
      border-radius: 0;
      border: 0px none;
      padding: 0px;
      -webkit-box-shadow: none;
      box-shadow: none;

      &:hover {
        background-color: $gray-superlight;
      }
    }
  }

  .choose-account-invitation {
    .ant-btn {
      border-radius: 0;
      border: 0px none;
      padding: 0px;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }
}

.hover {
  position: relative;

  &:hover &__no-hover {
    opacity: 0;
    width: 0px;
  }

  &:hover &__hover {
    opacity: 1;
    width: 100%;
    transition: width 0.3s;
    // transition-timing-function: ease-in-out;
  }

  &__hover {
    position: absolute;
    top: 0;
    opacity: 0;
    width: 0px;
  }

  &__no-hover {
    opacity: 1;
  }
}