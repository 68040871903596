.ant-tree {
  line-height: $line-height-base;
  color: $body-color;
  font-size: $font-size-base;

  .ant-tree-node-content-wrapper {
    &:hover {
      background-color: $tree-hover-color;
    }

    &.ant-tree-node-selected {
      background-color: rgba($tree-theme-color, 0.1);
      color: $tree-theme-color;
    }
  }
}

.ant-tree-checkbox {
  cursor: pointer;
  display: inline-block;
  position: relative;
  vertical-align: middle;
}

.ant-tree-checkbox,
.ant-tree-checkbox-wrapper {
  font-size: $font-size-base;
  color: $body-color;
}

.ant-tree-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: $checkbox-width-height;
  height: $checkbox-width-height;
  border: 1px solid $border-color;
  border-radius: $border-radius;
  background-color: $white;

  &:after {
    left: $checkbox-position-left;
    top: $checkbox-position-top;
  }
}

.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
  border-color: $tree-theme-color;
}

.ant-tree-checkbox-indeterminate {
  .ant-tree-checkbox-inner {
    background-color: $white;
    border-color: $border-color;

    &:after {
      background-color: $checkbox-theme-color;
    }
  }
}

.ant-tree-checkbox-checked {
  .ant-tree-checkbox-inner {
    background-color: $checkbox-theme-color;
    border-color: $checkbox-theme-color;
  }
}

.ant-tree-checkbox-disabled {
  cursor: not-allowed;

  .ant-tree-checkbox-inner {
    border-color: $border-color !important;
    background-color: $checkbox-disable-bg;
  }

  .ant-tree-checkbox-input {
    cursor: not-allowed;
  }
}

li.ant-tree-treenode-disabled {
  > .ant-tree-node-content-wrapper {
    color: rgba($body-color, 0.25);
    cursor: not-allowed;

    span {
      color: rgba($body-color, 0.25);
      cursor: not-allowed;
    }

    &:hover {
      background-color: transparent;
    }
  }

  > span:not(.ant-tree-switcher) {
    color: rgba($body-color, 0.25);
    cursor: not-allowed;
  }
}

.custom-node {
  cursor: pointer;
  line-height: 24px;
  margin-left: 4px;
  display: inline-block;
  margin: 0 -1000px;
  padding: 0 1000px;
}

.file-name,
.folder-name {
  margin-left: 4px;
}

.file-desc,
.folder-desc {
  padding: 0 8px;
  display: inline-block;
  background: rgba($tree-theme-color, 0.5);
  color: $white;
  position: relative;
  left: 12px;
}
