.table-alerts {
    .ant-table-thead {
        tr {
            th {
                background: #ffffff;
                min-height: 56px;
            }

            th:nth-child(1) {
                border-left: 1px solid #f0f0f0;
                border-top: 1px solid #f0f0f0;
            }

            th:nth-child(2) {
                border-top: 1px solid #f0f0f0;
            }

            th:nth-child(3) {
                border-top: 1px solid #f0f0f0;
            }

            th:nth-child(4) {
                border-top: 1px solid #f0f0f0;
                border-right: 1px solid #f0f0f0;
            }
        }
    }

    .ant-table-tbody {
        tr {
            td:nth-child(1) {
                border-left: 1px solid #f0f0f0;
            }

            td:nth-child(4) {
                border-right: 1px solid #f0f0f0;
            }

            // &:hover {
            //     background: red;
            // }
        }
    }
}

