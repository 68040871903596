.uptime-monitor-vertical-timeline {
  .ant-timeline-item-label {
    width: calc(15% - 12px) !important;
  }

  .ant-timeline.ant-timeline-alternate .ant-timeline-item-tail,
  .ant-timeline.ant-timeline-right .ant-timeline-item-tail,
  .ant-timeline.ant-timeline-label .ant-timeline-item-tail,
  .ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
  .ant-timeline.ant-timeline-right .ant-timeline-item-head,
  .ant-timeline.ant-timeline-label .ant-timeline-item-head,
  .ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
  .ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
  .ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
    left: 15% !important;
  }

  .ant-timeline.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content,
  .ant-timeline.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content,
  .ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content {
    left: calc(15% - 4px) !important;
    width: calc(80% - 14px) !important;
  }

  .timeline-item {
    line-height: 1.8em;
  }
}

.uptime-monitors-results-menu {
  .resultstable-failure {
    font-size: 11px;
    padding: 10px;
    border-bottom: 3px solid #ff0000;
  }

  .resultstable-success {
    font-size: 11px;
    padding: 10px;
    background: 3px solid rgba(0, 255, 0, 0.1);
    border-bottom: 3px solid #90ee90;
  }

  .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover,
  .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover,
  .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active,
  .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active,
  .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open,
  .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open,
  .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected,
  .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected .ant-menu-active {
    color: #1890ff;
    border-bottom: 3px solid #1890ff;
  }

  .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item,
  .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu {
    margin: 0 10px;
  }
}

.uptime-monitor {
  .uptime-monitor-custom-tooltip {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .apexcharts-tooltip.apexcharts-theme-light {
    background: rgb(255, 255, 255);
    background: rgba(255, 255, 255, 0.89) !important;
  }

  .ant-select-selector {
    border: 1px solid $border-color !important;
  }

  .ant-table-cell::before {
    background-color: $border-color !important;
  }

  .uptime-monitor-http {
    .apexcharts-series-markers {
      pointer-events: auto !important;
      cursor: pointer;
    }

    .apexcharts-marker {
      pointer-events: auto !important;
      cursor: pointer !important;
    }
  }

  .uptime-widgets > div.ant-card:last-child {
    margin-bottom: 0px;
  }

  @media (max-width: 992px) {
    .uptime-widgets > div.ant-card:first-child {
      margin-top: 20px;
    }
  }
}

.editable-label-group {
  padding-top: 10px;
  padding-bottom: 10px;

  .site-tag-plus {
    background: #fff;
    border-style: dashed;
    margin-top: 5px;
  }

  .edit-tag {
    user-select: none;
  }

  .tag-input {
    width: 78px;
    height: 27px;
    margin-right: 8px;
    vertical-align: top;
  }
}

