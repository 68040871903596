.free-trial-bar {
  position: fixed;
  left: 0;
  height: $ftbar-height;
  width: 100%;
  z-index: $zindex-fixed;
  // background: #feb62b;
  background: #FFC933;
  justify-content: center;
  text-align: center;

  .free-trial-info {
    position: relative;
    top: 10px;
  }
}
