.performance-monitor-page {
    .performance-monitor-performance-chart {
        .apexcharts-series-markers {
            pointer-events: auto !important;
            cursor: pointer;
        }

        .apexcharts-marker {
            pointer-events: auto !important;
            cursor: pointer !important;
        }
    }
}

.performance-monitor-page-drawer {
    .ant-drawer-close {
        padding: 6px;
        padding-right: 8px;
    }

    .ant-drawer-header {
        display: none
        
    }
}