.ant-layout {
  background: transparent;
}

.ant-layout-sider {
  background-color: $menu-dark-bg;
}

.bg-white {
  background-color: #f8f9fc !important;
}

.bg-light {
  background-color: #fafafb !important;
}

.pricing-rates {
  position: relative;
  z-index: 1;
  border-bottom: 3px solid $gray-500 !important;
  transition: all 0.5s ease;

  .price {
    font-weight: 400;
  }

  &.business-rate {
    &:hover {
      transform: scale(1.03);
      z-index: 2;
      border-color: $blue !important;
      box-shadow: $shadow-md !important;
      background-color: $white !important;

      .title {
        color: $blue;
      }
    }
  }
}

.table-row-critical {
  -webkit-box-shadow: 0px 0px 10px -3px rgba(214, 21, 21, 1);
  -moz-box-shadow: 0px 0px 10px -3px rgba(214, 21, 21, 1);
  box-shadow: 0px 0px 10px -3px rgba(214, 21, 21, 1);
}

.monitor-list-table {
  .ant-table-content {
    border-top: 1px solid $border-color;
    border-left: 1px solid $border-color;
    border-right: 1px solid $border-color;
  }
}
