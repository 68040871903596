.side-nav {
  top: 0px;
  margin-top: $header-height;
  box-shadow: $side-nav-box-shadow;
  border-right: $side-nav-border-right;

  background-color: $white;
  z-index: $zindex-navbar;

  @include print {
    display: none;
  }

  .ant-menu-vertical .ant-menu-item,
  .ant-menu-vertical-left .ant-menu-item,
  .ant-menu-vertical-right .ant-menu-item,
  .ant-menu-inline .ant-menu-item,
  .ant-menu-vertical .ant-menu-submenu-title,
  .ant-menu-vertical-left .ant-menu-submenu-title,
  .ant-menu-vertical-right .ant-menu-submenu-title,
  .ant-menu-inline .ant-menu-submenu-title {
    line-height: 2.5rem;
    height: 2.5rem;
  }

  &.side-nav-dark {
    background-color: $menu-dark-bg;
  }

  .hide-group-title {
    .ant-menu-item-group-title {
      display: none;
    }
  }
}

.side-nav-with-ftbar {
  @extend .side-nav;
  margin-top: $header-height + $ftbar-height;
}
