  .apex-charts-pointer-markers {
      .apexcharts-series-markers {
          pointer-events: auto !important;
          cursor: pointer;
      }

      .apexcharts-marker {
          pointer-events: auto !important;
          cursor: pointer !important;
      }
  }