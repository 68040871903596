.app-header {
  position: fixed;
  width: 100%;
  left: 0;
  z-index: 1000;
  display: flex;
  box-shadow: $header-box-shadow;
  border-bottom: $header-box-border-bottom;

  .app-header-wrapper {
    display: flex;
    width: 100%;

    &.layout-top-nav {
      max-width: $content-max-width;
      margin: auto;
    }
  }

  .nav-icon {
    font-size: 1.25rem;
  }

  &.ant-layout-header {
    height: $header-height;
    line-height: $header-height;
    padding: 0;
    background-color: $white;
  }

  .nav {
    display: flex;
    justify-content: space-between;
    position: relative;
    transition: all 0.2s ease;
  }

  .nav-right {
    margin-left: auto;
    padding: 0 1rem;
    display: flex;
  }

  .nav-left {
    display: flex;
  }

  .nav-right,
  .nav-left {
    .ant-menu {
      .ant-menu-item {
        padding: 0px 1rem;
        margin: 0;
        line-height: $header-height;
      }
    }
  }

  ul {
    &.ant-menu {
      line-height: $header-height;
      border-bottom: 0;
      background: transparent;
    }
  }

  .ant-menu-horizontal:not(.ant-menu-dark) {

    >.ant-menu-item:hover,
    >.ant-menu-submenu:hover,
    >.ant-menu-item-active,
    >.ant-menu-submenu-active,
    >.ant-menu-item-open,
    >.ant-menu-submenu-open,
    >.ant-menu-submenu-selected {
      border-bottom: 0;
      color: $primary;

      .ant-badge {
        color: $primary;
      }
    }

    >.ant-menu-item-selected {
      color: $menu-text;
      border-bottom: 0;

      .ant-badge {
        color: $menu-text;
      }
    }
  }

  &.light {
    .ant-menu {
      color: rgba($white, 0.8);

      &.ant-menu-horizontal:not(.ant-menu-dark) {

        >.ant-menu-item:hover,
        >.ant-menu-submenu:hover,
        >.ant-menu-item-active,
        >.ant-menu-submenu-active,
        >.ant-menu-item-open,
        >.ant-menu-submenu-open,
        >.ant-menu-submenu-selected {
          border-bottom: 0;
          color: $white;

          .ant-badge {
            color: $white;
          }
        }

        >.ant-menu-item-selected {
          color: rgba($white, 0.8);
          border-bottom: 0;

          .ant-badge {
            color: rgba($white, 0.8);
          }
        }
      }

      &:not(.ant-menu-dark) {
        a {
          color: rgba($white, 0.8);

          &:hover {
            color: $white;
          }
        }
      }
    }

    .ant-badge {
      color: rgba($white, 0.8);

      .ant-badge-count {
        box-shadow: none;
      }
    }
  }

  @include print {
    display: none;
  }
}

.app-header-with-ftbar {
  @extend .app-header;
  margin-top: $ftbar-height;
}

.nav-dropdown {
  background-color: $white;
  box-shadow: $dropdown-shadow;
  border-radius: $border-radius;
}