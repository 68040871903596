.ant-drawer {
  z-index: $zindex-modal;
}

.ant-drawer-header {
  padding: $modal-header-padding;
  border-radius: 4px 4px 0 0 !important;
  background: $white;
  color: $body-color;
  border-bottom: 1px solid $border-color;
}

.ant-drawer-footer {
  position: absolute;
  bottom: 0px;
  width: 100%;
  border-top: 1px solid $border-color;
  padding: $modal-footer-padding;
  left: 0px;
  background: $white;
}
