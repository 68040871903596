@import "./variables/variables.scss";
@import "./mixins/mixins.scss";

//*********************//
//**** Components ****//
//*******************//

// General
@import "./components/general/typorgraphy";
@import "./components/general/button";

// Layout
@import "./components/layout/layout";

// Navigation
@import "./components/navigation/affix";
@import "./components/navigation/breadcrumb";
@import "./components/navigation/dropdown";
@import "./components/navigation/menu";
@import "./components/navigation/page-header";
@import "./components/navigation/pagination";
@import "./components/navigation/steps";

// Data Entry
@import "./components/data-entry/cascader";
@import "./components/data-entry/checkbox";
@import "./components/data-entry/date-picker";
@import "./components/data-entry/input";
@import "./components//data-entry/input-number";
@import "./components/data-entry/form";
@import "./components/data-entry/mention";
@import "./components/data-entry/radio";
@import "./components/data-entry/rate";
@import "./components/data-entry/select";
@import "./components/data-entry/slider";
@import "./components/data-entry/switch";
@import "./components/data-entry/tree-select";
@import "./components/data-entry/time-picker";
@import "./components/data-entry/transfer";
@import "./components/data-entry/upload";

// Data Display
@import "./components/data-display/avatar";
@import "./components/data-display/badge";
@import "./components/data-display/comment";
@import "./components/data-display/collapse";
@import "./components/data-display/carousel";
@import "./components/data-display/card";
@import "./components/data-display/calendar";
@import "./components/data-display/descriptions";
@import "./components/data-display/empty";
@import "./components/data-display/list";
@import "./components/data-display/popover";
@import "./components/data-display/statistic";
@import "./components/data-display/tree";
@import "./components/data-display/timeline";
@import "./components/data-display/tag";
@import "./components/data-display/tabs";
@import "./components/data-display/table";

// Alerting
@import "./components/alerting/alert-table";

// Feedback
@import "./components/feedback/alert";
@import "./components/feedback/drawer";
@import "./components/feedback/message";
@import "./components/feedback/modal";
@import "./components/feedback/notifications";
@import "./components/feedback/progress";
@import "./components/feedback/skeleton";
@import "./components/feedback/spin";

// Other
@import "./components/others/anchor";
@import "./components/others/back-to-top";
@import "./components/others/divider";
@import "./custom/apexcharts.scss";
//****************************//
//**** Custom Components ****//
//**************************//

@import "./custom-components/ellipsis-dropdown";
@import "./custom-components/code-box";
@import "./custom-components/api-container";
@import "./custom-components/icon-display";

//****************************//
//********** Apps ***********//
//**************************//

@import "./apps/mail";
@import "./apps/chat";
@import "./apps/calendar";
@import "./apps//scrumboard";
@import "./apps/uptime-monitors";
@import "./apps/performance-monitors";
@import "./apps/rum";
@import "./apps/browser-monitors";
@import "./apps/generic";
@import "./apps/init-views";

//****************************//
//********* Vendors *********//
//**************************//

@import "./vendors/apex-chart";
@import "./vendors/quill";

//****************************//
//******** Template *********//
//**************************//

@import "./template/header";
@import "./template/ftbar";
@import "./template/side-nav";
@import "./template/top-nav";
@import "./template/logo";
@import "./template/page-header";
@import "./template/page-header-alt";
@import "./template/nav-notification";
@import "./template/nav-switch-account";
@import "./template/nav-profile";
@import "./template/content";
@import "./template/nav-search";
@import "./template/footer";



// CUSTOM DTEKT
.monitor-list-paused {
    .ant-table-row {
        background-color: $gray-superlight;
    }

    .ant-table-cell-row-hover {
        background-color: $gray-superlight;
    }
}

.monitor-list-failing {
    .ant-card {
        box-shadow: 0 1px 10px -1px rgba(255, 0, 0, 0.4);
    }
}

.ant-tour-mask {
    z-index: 1080 !important;
}

.ant-tour-create-account {
    .ant-tour-inner {
        .ant-tour-close {
            display: none;
        }
    }
}

div.choose-account {
    margin-top: $header-height + 100;
}

.ant-notification {
    z-index: 2000 !important;
}

.dollar:before {
    content: '$';
    font-size: 12px;
}

.displaynone {
    display: none;
}

.ant-btn-primary {
    background-color: #6d9f71 !important;
    border-color: #6d9f71 !important;

    &:hover {
        opacity: 0.9;
    }
}

.ant-tag-green {
    background-color: #6d9f71 !important;
    color: #ffffff !important;
}

// .ant-menu-title-content {
//     color: #2a4d14;
// }

// .ant-menu-item {
//     background-color: #6d9f71;
// }
