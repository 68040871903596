.ant-tag {
  margin-right: 0.625rem;
  display: inline-block;
  line-height: $tag-line-height;
  height: $tag-height;
  padding: 0 $tag-spacer;
  border-radius: $border-radius-sm;
  border: 1px solid $border-color;
  background: $tag-bg;
  font-size: $tag-font-size;
  color: $body-color;

  .anticon-cross {
    display: inline-block;
    cursor: pointer;
    margin-left: 3px;
    transition: all 0.3s;
    color: rgba($body-color, 0.45);
    font-weight: 700;
  }
}

.ant-tag-dtekt-green {
  color: #389e0d;
  background: #f6ffed;
  border: 1px solid #b7eb8f;
  border-radius: none;
}


.ant-tag-dtekt-green-clickable {
  &:hover {
    background: #edfbd5;
  }
  color: #389e0d;
  background: #f6ffed;
  border: 1px solid #b7eb8f;
  border-radius: none;
}


.ant-tag-dtekt-blue {
  border-radius: none;
  color: #096dd9;
  background: #e6f7ff;
  border-color: #91d5ff;
}

.ant-tag-checkable {
  background-color: transparent;
  border-color: transparent;

  &:active {
    background-color: darken($tag-theme-color, 10%);
    color: $white;
  }

  &:not(.ant-tag-checkable-checked) {
    &:hover {
      color: $tag-theme-color;
    }
  }
}

.ant-tag-checkable-checked {
  background-color: $tag-theme-color;
  color: $white;
}

.ant-tag-has-color {
  color: $white;

  .anticon-cross,
  a {
    color: $white;

    &:hover {
      color: $white;
    }
  }
}

@each $tone in $tone-loop {
  .ant-tag-#{nth($tone, 1)} {
    color: #{nth($tone, 2)};
    background: #{nth($tone, 3)};
    border-color: transparent;
    font-weight: 500;
  }
}

.m-b-0 {
  .ant-tag {
    margin-bottom: 0px;
  }
}

.m-r-0 {
  .ant-tag {
    margin-right: 0px;
  }
}

.editable-tag {
  .ant-tag {
    border-style: dashed;
  }
}
