.nav-switch-account {
  width: 10rem;

  .nav-switch-account-header {
    padding: 0.875rem;
    border-bottom: 1px solid $border-color;
  }

  .nav-switch-account-body {
    max-height: 21.875rem;
    overflow-y: auto;
  }

  .nav-switch-account-footer {
    padding: 0rem;
    text-align: center;
  }
}
