.real-user-monitoring {
    .ant-checkbox-input {
        width: 15px;
        height: 15px;
    }

    .ant-checkbox-inner {
        width: 15px;
        height: 15px;
    }

    .ant-list-item {
        padding: 2px;
        border-bottom: none;
    }

    .ant-checkbox-wrapper {
        width: 100%;
    }

    .label-list-exposed {
        -webkit-transition: width 1s ease-in-out;
        -moz-transition: width 1s ease-in-out;
        -o-transition: width 1s ease-in-out;
        transition: width 1s ease-in-out;
        z-index: 100;
        width:400px;
        box-shadow: 0 0 1em #dedede;
    }
}