.browser-monitor-vertical-timeline {
    .ant-timeline-item-label {
        width: calc(27% - 12px) !important;
    }

    .ant-timeline.ant-timeline-alternate .ant-timeline-item-tail,
    .ant-timeline.ant-timeline-right .ant-timeline-item-tail,
    .ant-timeline.ant-timeline-label .ant-timeline-item-tail,
    .ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
    .ant-timeline.ant-timeline-right .ant-timeline-item-head,
    .ant-timeline.ant-timeline-label .ant-timeline-item-head,
    .ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
    .ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
    .ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
        left: 27% !important;
    }

    .ant-timeline.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content,
    .ant-timeline.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content,
    .ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content {
        left: calc(27% - 4px) !important;
        width: calc(68% - 14px) !important;
    }

    .timeline-item {
        line-height: 1.8em;
    }
}